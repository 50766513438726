import React from "react";
import { Container, Icon, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  unauthorizedHeader,
  unauthorizedCopy,
  notFoundHeader,
  notFoundCopy,
} from "../config/copy";

const ErrorPage = ({ code, onHomeClick = () => {}, homeUrl }) => (
  <Container text>
    <Container id="logo" text textAlign="center">
      <Icon bordered name="circle" size="huge" style={{ color: "#008000" }} />
      <Icon bordered name="circle" size="huge" style={{ color: "#FFC857" }} />
      <Icon bordered name="circle" size="huge" style={{ color: "red" }} />
    </Container>
    <Header as="h2">
      {code === 401 ? unauthorizedHeader : notFoundHeader}
    </Header>
    <p>{code === 401 ? unauthorizedCopy : notFoundCopy}</p>
    <Button
      onMouseDown={() => onHomeClick(homeUrl || "/")}
      as={Link}
      to={homeUrl || "/"}
      data-testid={"home"}
    >
      {code === 401 ? "Sign in" : "Return home"}
    </Button>
  </Container>
);
export default ErrorPage;
